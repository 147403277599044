import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';
import { ICityTaxExemptionRequest, ICityTaxRequest } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class CityTaxService {
  constructor(private http: HttpClient) {}

  load(propertiesIds: number[]) {
    return this.http.get(
      `properties/city_tax?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  create(data: ICityTaxRequest) {
    return this.http.post(`properties/city_tax`, data);
  }

  delete(propertiesIds: number[]) {
    return this.http.delete(
      `properties/city_tax?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  update(data: ICityTaxRequest) {
    return this.http.put(`properties/city_tax`, data);
  }

  updateExemptions(data: ICityTaxExemptionRequest) {
    return this.http.post(`properties/city_tax_exemptions`, data);
  }

  loadExemptions(propertiesIds: number[]) {
    return this.http.get(
      `properties/city_tax_exemptions?${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }
}
