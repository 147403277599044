import { ICityTax, ICityTaxExemption } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getCityTaxExemptions = (state: State): ICityTaxExemption =>
  state.cityTaxExemptions;

export const getCityTaxData = (
  state: State,
): { [propertyId: number]: ICityTax } => state.cityTaxData;

export const selectCityTaxState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('cityTax');

export const selectCityTaxError: MemoizedSelector<object, any> = createSelector(
  selectCityTaxState,
  getError,
);

export const selectCityTaxIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectCityTaxState, getIsLoading);

export const selectCityTaxExemptions: MemoizedSelector<
  object,
  ICityTaxExemption
> = createSelector(selectCityTaxState, getCityTaxExemptions);
export const selectCityTaxData: MemoizedSelector<
  object,
  { [propertyId: number]: ICityTax }
> = createSelector(selectCityTaxState, getCityTaxData);
