import { ICityTax, ICityTaxExemption } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<ICityTax> = createEntityAdapter<
  ICityTax
>({
  selectId: (model) => model.property_id[0],
});

export interface State extends EntityState<ICityTax> {
  isLoading?: boolean;
  error?: any;
  cityTaxExemptions: ICityTaxExemption;
  cityTaxData: { [propertyId: number]: ICityTax };
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  cityTaxExemptions: null,
  cityTaxData: null,
});
