import {
  ICityTax,
  ICityTaxExemption,
  ICityTaxExemptionRequest,
  ICityTaxRequest,
} from '@app/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[City Tax] Load Request',
  LOAD_FAILURE = '[City Tax] Load Failure',
  LOAD_SUCCESS = '[City Tax] Load Success',

  CREATE_REQUEST = '[City Tax] Create Request',
  CREATE_FAILURE = '[City Tax] Create Failure',
  CREATE_SUCCESS = '[City Tax] Create Success',

  DELETE_REQUEST = '[City Tax] Delete Request',
  DELETE_FAILURE = '[City Tax] Delete Failure',
  DELETE_SUCCESS = '[City Tax] Delete Success',

  UPDATE_REQUEST = '[City Tax] Update Request',
  UPDATE_FAILURE = '[City Tax] Update Failure',
  UPDATE_SUCCESS = '[City Tax] Update Success',

  LOAD_EXEMPTION_REQUEST = '[City Tax] Load Exemption Request',
  LOAD_EXEMPTION_FAILURE = '[City Tax] Load Exemption Failure',
  LOAD_EXEMPTION_SUCCESS = '[City Tax] Load Exemption Success',

  UPDATE_EXEMPTION_REQUEST = '[City Tax] Update Exemption  Request',
  UPDATE_EXEMPTION_FAILURE = '[City Tax] Update Exemption Failure',
  UPDATE_EXEMPTION_SUCCESS = '[City Tax] Update Exemption Success',

  RESET_STATE = '[City Tax] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: { [propertyId: number]: ICityTax } }) {}
}
export class LoadExemptionRequestAction implements Action {
  readonly type = ActionTypes.LOAD_EXEMPTION_REQUEST;
  constructor(public payload: { propertyId: number[] }) {}
}

export class LoadExemptionFailureAction implements Action {
  readonly type = ActionTypes.LOAD_EXEMPTION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadExemptionSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_EXEMPTION_SUCCESS;
  constructor(public payload: { items: ICityTaxExemption }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(public payload: { request: ICityTaxRequest }) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { item: ICityTax }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { propertyId?: number[] }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { propertyId: number[] }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(public payload: { request: ICityTaxRequest }) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { cityTax: ICityTax }) {}
}
export class UpdateExemptionRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_EXEMPTION_REQUEST;
  constructor(public payload: { request: ICityTaxExemptionRequest }) {}
}

export class UpdateExemptionFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_EXEMPTION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateExemptionSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_EXEMPTION_SUCCESS;
  constructor(public payload: { cityTaxExemptions: ICityTaxExemption }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadExemptionRequestAction
  | LoadExemptionFailureAction
  | LoadExemptionSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | UpdateExemptionRequestAction
  | UpdateExemptionSuccessAction
  | UpdateExemptionFailureAction
  | ResetSuccessAction;
