import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { CityTaxService } from '@app/services/city-tax.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class CityTaxStoreEffects {
  constructor(
    private dataService: CityTaxService,
    private actions$: Actions,
    private store: Store<{ core: ICoreState }>,
    private _notifications: NotificationService,
    private _translate: TranslateService,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const { propertyId } = action.payload;
        return this.dataService.load(propertyId).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadExemptionRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadExemptionRequestAction>(
        featureActions.ActionTypes.LOAD_EXEMPTION_REQUEST,
      ),
      switchMap((action: featureActions.LoadExemptionRequestAction) => {
        const { propertyId } = action.payload;
        return this.dataService.loadExemptions(propertyId).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadExemptionSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadExemptionFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.CreateRequestAction,
          { core: ICoreState },
        ]) => {
          let propertiesIds = action.payload.request.property_id;
          if (!propertiesIds) {
            propertiesIds = store.core.allProperties.map(({ id }) => id);
          }
          const request = {
            ...action.payload.request,
            propertyId: propertiesIds,
          };
          return this.dataService.create(request).pipe(
            map(({ data }: any) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.create_success',
                  {
                    param: this._translate.instant('city_tax'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.CreateSuccessAction({
                item: data[0],
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.DeleteRequestAction,
          { core: ICoreState },
        ]) => {
          const { propertyId } = action.payload;
          return this.dataService.delete(propertyId).pipe(
            map(
              () =>
                new featureActions.DeleteSuccessAction({
                  propertyId,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([{ payload }, store]: [
          featureActions.UpdateRequestAction,
          { core: ICoreState },
        ]) => {
          const { request } = payload;
          return this.dataService.update(request).pipe(
            map((response: IResponseSuccess) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.update_success',
                  {
                    param: this._translate.instant('city_tax'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.UpdateSuccessAction({
                cityTax: response.data[0],
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  updateExemptionRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateExemptionRequestAction>(
        featureActions.ActionTypes.UPDATE_EXEMPTION_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([{ payload }, store]: [
          featureActions.UpdateExemptionRequestAction,
          { core: ICoreState },
        ]) => {
          const { request } = payload;
          return this.dataService.updateExemptions(request).pipe(
            map(
              (response: IResponseSuccess) =>
                new featureActions.UpdateExemptionSuccessAction({
                  cityTaxExemptions: response.data[0],
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.UpdateExemptionFailureAction({ error }),
              );
            }),
          );
        },
      ),
    ),
  );
}
