import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        cityTaxData: action.payload.items,
      };
    }
    case ActionTypes.LOAD_EXEMPTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_EXEMPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        cityTaxExemptions: action.payload.items,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      let newState = state;
      action.payload.propertyId.forEach((propertyId) => {
        newState = featureAdapter.removeOne(propertyId, {
          ...newState,
          isLoading: false,
          error: null,
        });
      });
      return newState;
    }
    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return { ...state, isLoading: false, error: null };
    }

    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_EXEMPTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_EXEMPTION_SUCCESS: {
      return { ...state, isLoading: false, error: null };
    }

    case ActionTypes.UPDATE_EXEMPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
